exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-applications-jsx": () => import("./../../../src/pages/applications.jsx" /* webpackChunkName: "component---src-pages-applications-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contribute-jsx": () => import("./../../../src/pages/contribute.jsx" /* webpackChunkName: "component---src-pages-contribute-jsx" */),
  "component---src-pages-es-applications-jsx": () => import("./../../../src/pages/es/applications.jsx" /* webpackChunkName: "component---src-pages-es-applications-jsx" */),
  "component---src-pages-es-case-studies-jsx": () => import("./../../../src/pages/es/case-studies.jsx" /* webpackChunkName: "component---src-pages-es-case-studies-jsx" */),
  "component---src-pages-es-contribute-jsx": () => import("./../../../src/pages/es/contribute.jsx" /* webpackChunkName: "component---src-pages-es-contribute-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-es-infrastructure-jsx": () => import("./../../../src/pages/es/infrastructure.jsx" /* webpackChunkName: "component---src-pages-es-infrastructure-jsx" */),
  "component---src-pages-fr-fr-applications-jsx": () => import("./../../../src/pages/fr-fr/applications.jsx" /* webpackChunkName: "component---src-pages-fr-fr-applications-jsx" */),
  "component---src-pages-fr-fr-case-studies-jsx": () => import("./../../../src/pages/fr-fr/case-studies.jsx" /* webpackChunkName: "component---src-pages-fr-fr-case-studies-jsx" */),
  "component---src-pages-fr-fr-contribute-jsx": () => import("./../../../src/pages/fr-fr/contribute.jsx" /* webpackChunkName: "component---src-pages-fr-fr-contribute-jsx" */),
  "component---src-pages-fr-fr-index-jsx": () => import("./../../../src/pages/fr-fr/index.jsx" /* webpackChunkName: "component---src-pages-fr-fr-index-jsx" */),
  "component---src-pages-fr-fr-infrastructure-jsx": () => import("./../../../src/pages/fr-fr/infrastructure.jsx" /* webpackChunkName: "component---src-pages-fr-fr-infrastructure-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infrastructure-jsx": () => import("./../../../src/pages/infrastructure.jsx" /* webpackChunkName: "component---src-pages-infrastructure-jsx" */),
  "component---src-pages-it-it-applications-jsx": () => import("./../../../src/pages/it-it/applications.jsx" /* webpackChunkName: "component---src-pages-it-it-applications-jsx" */),
  "component---src-pages-it-it-case-studies-jsx": () => import("./../../../src/pages/it-it/case-studies.jsx" /* webpackChunkName: "component---src-pages-it-it-case-studies-jsx" */),
  "component---src-pages-it-it-contribute-jsx": () => import("./../../../src/pages/it-it/contribute.jsx" /* webpackChunkName: "component---src-pages-it-it-contribute-jsx" */),
  "component---src-pages-it-it-index-jsx": () => import("./../../../src/pages/it-it/index.jsx" /* webpackChunkName: "component---src-pages-it-it-index-jsx" */),
  "component---src-pages-it-it-infrastructure-jsx": () => import("./../../../src/pages/it-it/infrastructure.jsx" /* webpackChunkName: "component---src-pages-it-it-infrastructure-jsx" */),
  "component---src-pages-ko-kr-applications-jsx": () => import("./../../../src/pages/ko-kr/applications.jsx" /* webpackChunkName: "component---src-pages-ko-kr-applications-jsx" */),
  "component---src-pages-ko-kr-case-studies-jsx": () => import("./../../../src/pages/ko-kr/case-studies.jsx" /* webpackChunkName: "component---src-pages-ko-kr-case-studies-jsx" */),
  "component---src-pages-ko-kr-contribute-jsx": () => import("./../../../src/pages/ko-kr/contribute.jsx" /* webpackChunkName: "component---src-pages-ko-kr-contribute-jsx" */),
  "component---src-pages-ko-kr-index-jsx": () => import("./../../../src/pages/ko-kr/index.jsx" /* webpackChunkName: "component---src-pages-ko-kr-index-jsx" */),
  "component---src-pages-ko-kr-infrastructure-jsx": () => import("./../../../src/pages/ko-kr/infrastructure.jsx" /* webpackChunkName: "component---src-pages-ko-kr-infrastructure-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-pt-applications-jsx": () => import("./../../../src/pages/pt/applications.jsx" /* webpackChunkName: "component---src-pages-pt-applications-jsx" */),
  "component---src-pages-pt-br-applications-jsx": () => import("./../../../src/pages/pt-br/applications.jsx" /* webpackChunkName: "component---src-pages-pt-br-applications-jsx" */),
  "component---src-pages-pt-br-case-studies-jsx": () => import("./../../../src/pages/pt-br/case-studies.jsx" /* webpackChunkName: "component---src-pages-pt-br-case-studies-jsx" */),
  "component---src-pages-pt-br-contribute-jsx": () => import("./../../../src/pages/pt-br/contribute.jsx" /* webpackChunkName: "component---src-pages-pt-br-contribute-jsx" */),
  "component---src-pages-pt-br-index-jsx": () => import("./../../../src/pages/pt-br/index.jsx" /* webpackChunkName: "component---src-pages-pt-br-index-jsx" */),
  "component---src-pages-pt-br-infrastructure-jsx": () => import("./../../../src/pages/pt-br/infrastructure.jsx" /* webpackChunkName: "component---src-pages-pt-br-infrastructure-jsx" */),
  "component---src-pages-pt-case-studies-jsx": () => import("./../../../src/pages/pt/case-studies.jsx" /* webpackChunkName: "component---src-pages-pt-case-studies-jsx" */),
  "component---src-pages-pt-contribute-jsx": () => import("./../../../src/pages/pt/contribute.jsx" /* webpackChunkName: "component---src-pages-pt-contribute-jsx" */),
  "component---src-pages-pt-index-jsx": () => import("./../../../src/pages/pt/index.jsx" /* webpackChunkName: "component---src-pages-pt-index-jsx" */),
  "component---src-pages-pt-infrastructure-jsx": () => import("./../../../src/pages/pt/infrastructure.jsx" /* webpackChunkName: "component---src-pages-pt-infrastructure-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-summit-2023-jsx": () => import("./../../../src/pages/summit-2023.jsx" /* webpackChunkName: "component---src-pages-summit-2023-jsx" */),
  "component---src-pages-summit-2023-talks-jsx": () => import("./../../../src/pages/summit-2023-talks.jsx" /* webpackChunkName: "component---src-pages-summit-2023-talks-jsx" */),
  "component---src-pages-summit-2024-jsx": () => import("./../../../src/pages/summit-2024.jsx" /* webpackChunkName: "component---src-pages-summit-2024-jsx" */),
  "component---src-pages-summit-2024-schedule-jsx": () => import("./../../../src/pages/summit-2024-schedule.jsx" /* webpackChunkName: "component---src-pages-summit-2024-schedule-jsx" */),
  "component---src-pages-summit-2024-talks-jsx": () => import("./../../../src/pages/summit-2024-talks.jsx" /* webpackChunkName: "component---src-pages-summit-2024-talks-jsx" */),
  "component---src-pages-sw-applications-jsx": () => import("./../../../src/pages/sw/applications.jsx" /* webpackChunkName: "component---src-pages-sw-applications-jsx" */),
  "component---src-pages-sw-case-studies-jsx": () => import("./../../../src/pages/sw/case-studies.jsx" /* webpackChunkName: "component---src-pages-sw-case-studies-jsx" */),
  "component---src-pages-sw-contribute-jsx": () => import("./../../../src/pages/sw/contribute.jsx" /* webpackChunkName: "component---src-pages-sw-contribute-jsx" */),
  "component---src-pages-sw-index-jsx": () => import("./../../../src/pages/sw/index.jsx" /* webpackChunkName: "component---src-pages-sw-index-jsx" */),
  "component---src-pages-sw-infrastructure-jsx": () => import("./../../../src/pages/sw/infrastructure.jsx" /* webpackChunkName: "component---src-pages-sw-infrastructure-jsx" */),
  "component---src-pages-swag-jsx": () => import("./../../../src/pages/swag.jsx" /* webpackChunkName: "component---src-pages-swag-jsx" */),
  "component---src-pages-zh-hans-applications-jsx": () => import("./../../../src/pages/zh-hans/applications.jsx" /* webpackChunkName: "component---src-pages-zh-hans-applications-jsx" */),
  "component---src-pages-zh-hans-case-studies-jsx": () => import("./../../../src/pages/zh-hans/case-studies.jsx" /* webpackChunkName: "component---src-pages-zh-hans-case-studies-jsx" */),
  "component---src-pages-zh-hans-contribute-jsx": () => import("./../../../src/pages/zh-hans/contribute.jsx" /* webpackChunkName: "component---src-pages-zh-hans-contribute-jsx" */),
  "component---src-pages-zh-hans-index-jsx": () => import("./../../../src/pages/zh-hans/index.jsx" /* webpackChunkName: "component---src-pages-zh-hans-index-jsx" */),
  "component---src-pages-zh-hans-infrastructure-jsx": () => import("./../../../src/pages/zh-hans/infrastructure.jsx" /* webpackChunkName: "component---src-pages-zh-hans-infrastructure-jsx" */),
  "component---src-pages-zh-hant-applications-jsx": () => import("./../../../src/pages/zh-hant/applications.jsx" /* webpackChunkName: "component---src-pages-zh-hant-applications-jsx" */),
  "component---src-pages-zh-hant-case-studies-jsx": () => import("./../../../src/pages/zh-hant/case-studies.jsx" /* webpackChunkName: "component---src-pages-zh-hant-case-studies-jsx" */),
  "component---src-pages-zh-hant-contribute-jsx": () => import("./../../../src/pages/zh-hant/contribute.jsx" /* webpackChunkName: "component---src-pages-zh-hant-contribute-jsx" */),
  "component---src-pages-zh-hant-index-jsx": () => import("./../../../src/pages/zh-hant/index.jsx" /* webpackChunkName: "component---src-pages-zh-hant-index-jsx" */),
  "component---src-pages-zh-hant-infrastructure-jsx": () => import("./../../../src/pages/zh-hant/infrastructure.jsx" /* webpackChunkName: "component---src-pages-zh-hant-infrastructure-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-11-20-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2020-11-20-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-11-20-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-12-21-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2020-12-21-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-12-21-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-01-22-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-01-22-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-01-22-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-02-23-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-02-23-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-02-23-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-05-04-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-05-04-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-05-04-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2022-11-17-why-ebpf-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2022-11-17-why-ebpf/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2022-11-17-why-ebpf-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-04-01-excelbpf-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2023-04-01-excelbpf/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-04-01-excelbpf-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-11-08-ebpf-documentary-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2023-11-08-ebpf-documentary/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-11-08-ebpf-documentary-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-06-13-ebpf-licensing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2024-06-13-ebpf-licensing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-06-13-ebpf-licensing-index-md" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-get-started-jsx": () => import("./../../../src/templates/get-started.jsx" /* webpackChunkName: "component---src-templates-get-started-jsx" */),
  "component---src-templates-labs-jsx": () => import("./../../../src/templates/labs.jsx" /* webpackChunkName: "component---src-templates-labs-jsx" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-es-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.es.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-es-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-fr-fr-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.fr-fr.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-fr-fr-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-it-it-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.it-it.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-it-it-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ko-kr-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.ko-kr.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ko-kr-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-br-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.pt-br.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-br-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.pt.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-sw-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.sw.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-sw-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hans-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.zh-hans.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hans-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hant-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.zh-hant.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hant-md" */)
}

